import { useRef, useState, Fragment } from "react";
import { PlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

import { getUploadingFileData, updateProfileType, uploadFileToAWS } from "../../../../../service";
import ToggleComponent from "../../../../common/toggleComponent";
import { Button } from "../../../../form";
import { RefreshIcon } from "../../../../../asssets/icons";

const VideoEditor = ({ org, profile, onSubmit = () => {} }) => {
  console.log("profile as props", profile);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [videos, setVideos] = useState(profile?.videosInformation || []);
  const [customPoster, setCustomPoster] = useState(
    profile?.customPosters?.find((item) => item.id === "video-tips") || {
      title: "Custom Poster",
      description: "Custom Poster",
      isActive: false,
      id: "video-tips"
    }
  );

  const handleAddNewVideoItem = () => {
    const videoDetails = JSON.parse(JSON.stringify(videos));
    videoDetails.push({
      title: "New title",
      description: "New Description",
      isActive: false,
      videoID: videoDetails?.length + 1
    });
    setVideos(videoDetails);
  };

  const handleVideoItemToggle = (videoItemIndex, feature, value, type = "video") => {
    if (type === "customPoster") {
      setCustomPoster({ ...customPoster, [feature]: value ? value : !customPoster[feature] });
    } else {
      const updatedList = [...videos];
      updatedList[videoItemIndex][feature] = value ? value : !updatedList[videoItemIndex][feature];
      setVideos(updatedList);
    }
  };

  const handleFormSubmit = async () => {
    if (!videos.length || !profile?._id) {
      return;
    }

    try {
      setIsFormSubmitting(true);
      const customPosterTemp = { ...customPoster };
      if (customPosterTemp.thumbnail?.type) {
        const {
          data: { url, finalName }
        } = await getUploadingFileData({
          name:
            "video-thumbnail/" +
            customPosterTemp.id +
            "-" +
            new Date().getTime() +
            "-" +
            customPosterTemp.thumbnail?.name,
          type: customPosterTemp.thumbnail.type
        });
        await uploadFileToAWS(url, customPoster.thumbnail, customPoster.thumbnail.type);
        customPosterTemp.thumbnail = finalName;
      }

      let customPosterArray = profile.customPosters || [];
      const posterIndex = customPosterArray.findIndex((item) => item.id === "video-tips");
      if (posterIndex > -1) {
        customPosterArray[posterIndex] = customPosterTemp;
      } else {
        customPosterArray.push(customPosterTemp);
      }

      for (const video of videos) {
        if (video.thumbnail?.type) {
          const {
            data: { url, finalName }
          } = await getUploadingFileData({
            name:
              "video-thumbnail/" +
              video.videoID +
              "-" +
              new Date().getTime() +
              "-" +
              video.thumbnail?.name,
            type: video.thumbnail.type
          });
          await uploadFileToAWS(url, video.thumbnail, video.thumbnail?.type);
          video.thumbnail = finalName;
        }
      }

      await updateProfileType(org._id, {
        _id: profile._id,
        type: "videosInformation",
        videosInformation: videos,
        customPosters: customPosterArray
      });
      toast.success("Videos Information saved successfully.");
      onSubmit();
    } catch (error) {
      toast.error("Failed to save the form.");
      console.error("Failed to save the form: ", error);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const handleVideoThumbnailDelete = (index, type) => {
    if (type === "customPoster") {
      const updatedList = { ...customPoster };
      updatedList.thumbnail = null;
      setCustomPoster(updatedList);
    } else {
      const updatedList = [...videos];
      updatedList[index].thumbnail = null;
      setVideos(updatedList);
    }
  };

  return (
    <>
      <div>
        <VideoEditorColumn
          title="Video Tips Section"
          video={customPoster}
          videoItemIndex={0}
          handleVideoItemToggle={(index, feature, value) =>
            handleVideoItemToggle(index, feature, value, "customPoster")
          }
          deleteVideoThumbnail={(index) => handleVideoThumbnailDelete(index, "customPoster")}
        />
        <div className="border-b-2 my-5"></div>

        {videos.map((videoItem, videoItemIndex) => (
          <Fragment key={`video-editor-${videoItemIndex}`}>
            <VideoEditorColumn
              video={videoItem}
              videoItemIndex={videoItemIndex}
              handleVideoItemToggle={handleVideoItemToggle}
              deleteVideoThumbnail={handleVideoThumbnailDelete}
            />
          </Fragment>
        ))}
      </div>
      <div className="flex justify-between items-center mt-4 border-t-2 pt-2">
        <PlusIcon
          className={`w-7 h-7 stroke-2 stroke-white bg-[#606060] rounded-md cursor-pointer`}
          onClick={handleAddNewVideoItem}
        />
        <Button.AdminPanelButton
          type="submit"
          isSave={true}
          loading={isFormSubmitting}
          bgColor="#515151"
          textColor="#FFFFFF"
          color="white"
          onClick={handleFormSubmit}>
          Save
        </Button.AdminPanelButton>
      </div>
    </>
  );
};

export default VideoEditor;

export const VideoEditorColumn = ({
  title = null,
  videoItemIndex,
  video = {},
  handleVideoItemToggle = () => {},
  deleteVideoThumbnail = () => {}
}) => {
  const fileInputRef = useRef(null);
  const [editMode] = useState(false);

  const getImageLink = (value) => {
    // new image
    if (value?.type) {
      return URL.createObjectURL(value);
    }
    return process.env.REACT_APP_AWS_URL + value;
  };

  const setThumbnail = (file) => {
    if (!file) return;
    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size should be less than 2MB");
      return;
    }
    handleVideoItemToggle(videoItemIndex, "thumbnail", file);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="mt-5">
          {editMode ? (
            <div className="flex flex-col gap-y-2">
              <input
                type="text"
                value={video.title}
                onChange={(e) => handleVideoItemToggle(videoItemIndex, "title", e.target.value)}
                className="border-b-2 border-black"
              />
            </div>
          ) : (
            <>
              <h2 className="font-bold">{title ? title : `Video ${videoItemIndex + 1}`}</h2>
            </>
          )}
        </div>

        <div className="mt-5 flex items-center gap-3">
          <ToggleComponent
            handleCompToggle={() => handleVideoItemToggle(videoItemIndex, "isActive")}
            dataTestid="video-toggle"
            element="is-video-active"
            isToggle={video.isActive}
          />
        </div>
      </div>
      <div>
        <p className="font-bold text-sm mb-1 mt-3">Upload Video Thumbnail</p>
        {video.thumbnail ? (
          <div className="group relative inline-block h-full">
            {/* Remove Icon */}
            <div
              className="hidden group-hover:block absolute -top-2 -right-2 w-6 h-6 bg-black cursor-pointer rounded-full z-10"
              onClick={() => {
                console.log("CLICKED");
                deleteVideoThumbnail(videoItemIndex);
                if (fileInputRef.current) fileInputRef.current.value = "";
              }}>
              <XCircleIcon className="w-6 h-6 text-red-500" />
            </div>
            <label
              htmlFor="imageInput"
              className="relative block w-max group h-full"
              onClick={() => {
                if (fileInputRef.current) fileInputRef.current.click();
              }}>
              {/* Reload logo */}
              <div className="hidden group-hover:flex absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-black opacity-50 cursor-pointer items-center justify-center">
                <RefreshIcon color="#FFFFFF" />
              </div>

              <div className="flex justify-center bg-[#F9F9F9] rounded-md p-4 w-[200px] h-full">
                <div className="w-[80px] h-full rounded-md overflow-hidden">
                  <img
                    src={getImageLink(video.thumbnail)}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                </div>
              </div>
            </label>
          </div>
        ) : (
          <div
            className={
              "flex justify-start items-center px-4 py-3 cursor-pointer shadow-sm rounded-3xl bg-[#F5F5F5] w-full max-w-xs"
            }
            onClick={() => {
              fileInputRef.current.click();
            }}>
            <PlusIcon
              className={`w-7 h-7 stroke-2 stroke-white bg-[#606060] rounded-md cursor-pointer`}
            />
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          name="image"
          id={"imageInput" + videoItemIndex}
          onChange={(event) => {
            setThumbnail(event.currentTarget.files[0]);
          }}
          className={"hidden"}
        />
      </div>
    </div>
  );
};
