import React from "react";
import { InputSelect } from "../../../../form";
import { FaUnlink } from "react-icons/fa";

const ProfessionMapSelection = ({
  professionMaps,
  classProjectTypes,
  setClassProjectTypes,
  variant,
  variantDetails,
  type
}) => {
  const titles = {
    classOverallProfessionMap: "Class Overall",
    classGroupedProfessionMap: "Class Grouped",
    studentProfessionMap: "Student"
  };
  return (
    <div className="my-4">
      <div className="flex gap-3 items-end">
        <div className="w-full">
          <label className="block font-bold mb-1" htmlFor={variant}>
            {titles[type]} -
            <span className="text-cyan-600">
              {" "}
              {professionMaps?.find((pm) => pm._id === variantDetails?.[type])?.title}
            </span>
          </label>
          <InputSelect
            name={variant}
            className={"!mb-0"}
            value={{
              value: variantDetails?.[type],
              label: professionMaps?.find((pm) => pm._id === variantDetails?.[type])?.title
            }}
            options={professionMaps
              .map((map) => {
                return {
                  value: map._id,
                  label: map.title
                };
              })
              .filter((map) => map.value !== variantDetails?.[type])}
            onChange={(e) => {
              const temp = { ...classProjectTypes };
              if (!temp[variant][type]) temp[variant][type] = "";
              temp[variant][type] = e.value;
              setClassProjectTypes(temp);
            }}
          />
        </div>

        {classProjectTypes[variant]?.[type] && (
          <button
            className="rounded-full text-red-600 p-1 border-red-600 border-2 flex items-center justify-center hover:bg-red-600 hover:text-white"
            onClick={() => {
              const temp = { ...classProjectTypes };
              if (!temp[variant][type]) return;

              temp[variant][type] = "";
              setClassProjectTypes(temp);
            }}>
            <FaUnlink className="w-6 h-6" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProfessionMapSelection;
