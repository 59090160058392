import Spinner from "../spinner";

const sharedButtonClasses = "py-2 rounded font-bold text-md border";

export default function AdminPanelButton({
  children,
  type,
  onClick,
  isSave,
  isPrimary,
  textColor = "#FFFFFF",
  bgColor = "#000000",
  loading,
  color = "black",
  extraClasses,
  ...props
}) {
  let buttonClasses =
    isSave && `${sharedButtonClasses}  bg-[${bgColor}] text-[${textColor}] px-8 rounded-3xl`;
  return (
    <button
      className={
        "flex items-center gap-2 disabled:opacity-60 select-none " +
        buttonClasses +
        " " +
        extraClasses
      }
      onClick={onClick}
      type={type}
      disabled={loading}
      {...props}>
      <Spinner color={color} isSubmitting={loading} className="mr-2" />
      {children}
    </button>
  );
}
